/* eslint-disable react/no-danger */
import React from 'react'
import { Link } from 'gatsby'
import dayjs from 'dayjs'

import Image from './Image'
import './PostCard.css'

const PostCard = ({
  featuredImage,
  title,
  body,
  eventDate,
  extraPath = null,
  slug,
  categories = [],
  className = '',
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  const slg = extraPath ? `${extraPath}/${slug}` : slug
  return (
    <Link to={slg} state={extraPath} className={`PostCard ${className}`}>
      {eventDate && (
        <div
          style={{
            padding: 4,
            marginLeft: 8,
            color: '#606a2b'
          }}
        >
          {dayjs(eventDate).format('dddd, MMMM D YYYY')}
        </div>
      )}
      {featuredImage && (
        <div className="PostCard--Image relative">
          <Image background src={featuredImage} alt={title} />
        </div>
      )}
      <div className="PostCard--Content">
        {title && <h3 className="PostCard--Title">{title}</h3>}
        <div className="PostCard--Category">
          {categories && categories.map((cat) => cat.category).join(', ')}
        </div>

        <section className="PostCard--Excerpt">
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </section>
      </div>
    </Link>
  )
}

export default PostCard
